import apiClient from "@/plugins/apiClient";
import {toastFail, toastSuccess} from "@/utils/toastUtils";

export default{
    namespace:true,
    state: () => ({
        isLogin: false
    }),
    mutations: {
        isAuthenticateUser(state){
            state.isLogin=true;
        },
        isNotAuthenticateUser(state){
            state.isLogin=false;
        },
    },
    actions:{
        loginAction({ commit }, payload) {

            return apiClient.post('/auth/login', payload)
                .then(res => {
                    // api를 통해 토큰을 받아오면 token을 헤더와 쿠키에 저장하는 mutations을 호출함
                    if(res.data.resCode === '-1'){
                        toastFail(res.data.resMessage);
                        return Promise.reject(res.data.resMessage);
                    }
                    const accessToken = res.data.resData.token;

                    if(accessToken){
                        commit('isAuthenticateUser');
                        toastSuccess("로그인 성공하였습니다.");
                    }
                }).then(res => {
                    location.reload();
                })
        },
        logout({ commit }) {
            return apiClient.post('/api/public/logout')
                .then(res => {
                    if(res.data.resData == 0){
                        commit('isNotAuthenticateUser');
                    }
                }).catch(res => {
                    commit('isNotAuthenticateUser');
                })
        },
        async accessTokenValid ({ commit }){
            return await apiClient.post('/api/public/access-token/check')
            .then(res => {
                if (res.data.resData.code === 1) {
                    commit('isAuthenticateUser');
                    return true;
                } else {
                    return false;
                }
            })
            .catch(r => {
                return false;
            });
        },
        loginFalse({ commit }) {
            commit('isNotAuthenticateUser');
        },
    },
    getters: {
        isAuthenticated (state) {
            return state.isLogin;
        }
    }
}