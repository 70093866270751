<template>
  <div ref="modal_overlay" class="modal_overlay">
    <div class="login_modal_container">
      <div class="login_modal close_btn_area">
        <button type="button" class="close_btn" @click="$emit('closeLoginModal')">×</button>
      </div>
      <div class="logo_area"><div class="logo_img"></div></div>

      <p style="font-size: 25px; font-weight: 700;">환영합니다!</p>
      <p style="margin-top: 10px; font-size: 16px; font-weight: 600;">로그인 후 서비스를 이용해보세요!</p>
      <p style="margin-top: 10px; font-size: 13px; font-weight: 500; text-align: center">최초 가입 시 프로필 수정에서<br/>닉네임 변경이 가능합니다.</p>

      <div class="btn_area">
        <button class="kakao_btn" @click="kakaoLogin()">카카오 간편 로그인</button>
      </div>

      <p style="font-size: 12px;font-weight: bold; text-align: center;">
        로그인 시
        <a style="color:blue; cursor:pointer;" href="/term/privacy" target="_blank">개인정보 처리방침,</a>
        <a style="color:blue; cursor:pointer;" href="/term/service" target="_blank">이용약관</a>을
        <br/> 확인 및 동의한 것으로 간주합니다.
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LoginModalNew',
  data:() => ({
  }),
  created() {
  },
  methods: {
    kakaoLogin(){
      const domain = location.origin;
      location.href='https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=8e323b7756684c7205b6dbdff7d6cc87&redirect_uri=' + domain + '/api/public/oauth/callback';
    }
  },
}
</script>

<style scoped>
@import "@/assets/modal/login/LoginModalNew.css";
</style>