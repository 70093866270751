<template>
  <div ref="modal_overlay" class="modal_overlay">
    <div class="modal_profile_edit_container" v-if="!withdrawFlag">
      <div class="profile_edit_modal close_btn_area">
        <button type="button" class="close_btn" @click="$emit('closeProfileEditModal')">×</button>
      </div>
      <div class="profile_edit_content_wrap">

      </div>
      <div class="profile_img_area">
        <div class="profile">
          <img :src="profileImgUrl" class="profile_img_thumbnail" @error="defaultImg" @click="imgUpload"/>
        </div>
        <label class="profile_img_upload_btn_label" for="profile_img_upload_btn">프로필 업로드</label>
        <input type="file" id="profile_img_upload_btn" accept="image/*" class="profile_img_upload_btn" @change="setThumbnail"/>
      </div>
      <div class="my_info_area">
        <label id="input_label_name_edit" for="input_label_name_edit">닉네임</label>
        <input type="text" id="name_edit" name="name_edit" placeholder="이름을 입력하세요." :value="userName">
<!--
        <label id="input_label_introduce_edit" for="input_label_introduce_edit">한 줄 소개</label>
        <textarea id="introduce_edit" name="introduce_edit" placeholder="자신을 소개할 글을 입력하세요." :value="userIntroduceText"/>-->
      </div>

      <div class="btn_area">
        <button class="edit_submit_btn" @click="editSubmit">저장</button>

        <button class="withdraw_btn" @click="withdraw">회원탈퇴</button>
      </div>
    </div>

    <div class="modal_withdraw_container" v-else-if="withdrawFlag && !withdrawCompleteFlag">
      <div class="profile_edit_modal close_btn_area">
        <button type="button" class="close_btn" @click="$emit('closeProfileEditModal')">×</button>
      </div>
      <div class="logo_area"><div class="logo_img"></div></div>
      <div class="withdraw_txt_area">
        <p class="withdraw_title">
          회원탈퇴 유의사항
        </p>
        <p class="withdraw_txt">
          회원 탈퇴 시 개인정보(이메일)는 그 즉시 파기되며
        </p>
        <p class="withdraw_txt">
          개인정보 외 서비스를 사용하면서 저장된 데이터의 처리는 다음과 같습니다.
        </p>
        <br class="withdraw_br"/>
        <p class="withdraw_txt">
          · 삭제되는 데이터: 등록한 일일 퀴즈 및 일일 퀴즈 관련 이력 데이터, 퀴즈 답변/좋아요 관련 데이터
        </p>
        <br class="withdraw_br"/>
        <p class="withdraw_txt">
          · 보존되는 데이터: 등록한 퀴즈(숨김 처리)
        </p>
      </div>
      <div class="btn_area">
        <button class="cancel_btn" @click="withdrawFlag = false">취소</button>
        <button class="withdraw_btn" @click="withdrawComplete">탈퇴확정</button>
      </div>
    </div>

    <div class="modal_withdraw_container_complete" v-else>
      <div class="profile_edit_modal close_btn_area">
        <button type="button" class="close_btn" @click="$emit('closeProfileEditModal')">×</button>
      </div>

      <div class="logo_area"><div class="logo_img"></div></div>
      <p class="step_title">회원탈퇴 처리되었습니다.</p>
      <p class="step_desc">WIZLQ를 이용해 주셔서 감사합니다.</p>

      <div class="complete_img_area">
        <div class="complete_img"></div>
      </div>

      <div class="btn_area">
        <button type="button" class="login_btn" @click="goMain">홈으로</button>
      </div>
    </div>
  </div>
</template>

<script>

import apiClient from "@/plugins/apiClient";
import {isNullOrEmptyStr} from '@/utils/validationUtils';
import router from "@/utils/router";
import {mapActions} from "vuex";
import store from "@/store";
import {toast} from "vue3-toastify";
import {toastFail, toastSuccess} from "@/utils/toastUtils";

export default {
  props: ['profileEditModalOn'],
  name: 'ProfileEditModal',
  data: () => ({
    userName : '',
    userIntroduceText : '',
    profileImgUrl : '',
    withdrawFlag: false,
    withdrawCompleteFlag: false,
  }),
  created() {
      this.getUserInfo();
  },
  methods: {
    defaultImg(e){
      e.target.src = require('@/resource/img/person_l.png');
    },
    getUserInfo(){
      apiClient.get('/api/user').then(data => {
        this.userName = data.data.resData.userName;
        this.userIntroduceText = data.data.resData.userIntroduceText;
        this.profileImgUrl = data.data.resData.profileImgUrl;
      });
    },
    setThumbnail(event) {
      var reader = new FileReader();

      reader.onload = function(event) {
        $(".profile_img_thumbnail").attr("src", event.target.result);
      };

      if(event.target.files.length > 0){
        reader.readAsDataURL(event.target.files[0]);
      }
    },
    imgUpload(){
      $("#profile_img_upload_btn").click();
    },
    editSubmit(){
      var params = {};
      var profileUploadParams = {};
      let userName = $("#name_edit").val();
      let introduce = $("#introduce_edit").val();
      let profileImg = $("#profile_img_upload_btn")[0].files[0];

      if(isNullOrEmptyStr(userName)){
        toastFail("이름을 입력해주세요.");
        return false;
      }

      params.userName = userName;
      params.userIntroduceText = introduce;

        profileUploadParams.profileImg = profileImg;
        apiClient.post("/api/user/profile", profileUploadParams, {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        }).then(data => {
          //
        })

      apiClient.post("/api/user", params).then(data => {
        if(data.data.resData > 0) {
          toastSuccess('수정 성공하였습니다.');
          this.$emit('closeProfileEditModal');
          this.$emit('reloadMyInfo');
        }
      })
    },
    withdraw(){
      this.withdrawFlag = true;
    },
    withdrawComplete(){
      apiClient.post('/api/withdraw').then(data => {
        if (data.data.resCode == '00') {
          this.withdrawCompleteFlag = true;
          this.$store.dispatch('logout');
          this.$store.dispatch('loginFalse');
        }
      });
    },
    async goMain() {
      this.$emit('closeProfileEditModal');
      await router.push('/');
    }
  }
}
</script>

<style scoped>
  @import "@/assets/modal/profile/ProfileEditModal.css";
</style>